import React from 'react';

const defaultState = {
  active: false,
  toggleMenu: () => {},
}

const NavContext = React.createContext(defaultState)

class NavProvider extends React.Component {
  state = {
    active: false,
  }

  toggleMenu = () => {
    let active = !this.state.active
    this.setState({ active })
  }

  render() {
    const { children } = this.props
    const { active } = this.state
    return (
      <NavContext.Provider
        value={{
          active,
          toggleMenu: this.toggleMenu,
        }}
      >
        {children}
      </NavContext.Provider>
    )
  }
}

export default NavContext
export { NavProvider }